import type { User } from '@/types/User'

export const getToken = () => {
    return localStorage.getItem('token')
}
export const getUser = () => {
    return localStorage.getItem('user')
}
export const removeTokenAndUser = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
}
export const setTokenAndUser = (token: string, user:User) => {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', token)
}
export const getUploadRules = () => {
    return localStorage.getItem('uploadRules')
}
export const setUploadRules = () => {
    localStorage.setItem('uploadRules', 'true')
}
export const removeUploadRules = () => {
    localStorage.removeItem('uploadRules')
}
export const removeUserOnLogout = () => {
    localStorage.setItem('logout', 'true')
    removeTokenAndUser()
    removeUploadRules()
    localStorage.removeItem('remember-me')
    localStorage.removeItem('role')
    localStorage.removeItem('permissions')
    localStorage.removeItem('logout')
    window.location.replace('/sign-in')
}
