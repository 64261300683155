import { default as confirm_45emailD65kS8uvzmMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/confirm-email.vue?macro=true";
import { default as indexFV2CJAdmUvMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/account-settings/index.vue?macro=true";
import { default as indexrVw7w5X7pMMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/admin/index.vue?macro=true";
import { default as indexoZDRIh9S2YMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/listings/index.vue?macro=true";
import { default as index4WPWf1xRb3Meta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/properties/index.vue?macro=true";
import { default as agreementIZ0oLxgTqbMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/agreement.vue?macro=true";
import { default as indexj5b9OBorAWMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/checkout/index.vue?macro=true";
import { default as successrvfHhK0tGhMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/checkout/success.vue?macro=true";
import { default as listingMI5uUmWNKiMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/listing.vue?macro=true";
import { default as logsMHH6CVqUcYMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/logs.vue?macro=true";
import { default as open_45house_45schedulen1NxIwjP23Meta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/open-house-schedule.vue?macro=true";
import { default as optional_45servicesiJdRZbP7KcMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/optional-services.vue?macro=true";
import { default as post_45cardsFxpHuyrxJHMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/post-cards.vue?macro=true";
import { default as requester_45requestswYNwEW9QtyMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/requester-requests.vue?macro=true";
import { default as showings_45managementUXmjF8EDU0Meta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/showings-management.vue?macro=true";
import { default as managementENQQIXDxYAMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management.vue?macro=true";
import { default as indexuaM03rLIAvMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/waiting-screen/index.vue?macro=true";
import { default as indextvdeIz9AzbMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/tasks/index.vue?macro=true";
import { default as indexSelVxV52KYMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/dashboard/users/index.vue?macro=true";
import { default as confirmf0oqORSMNoMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/forgot-password/confirm.vue?macro=true";
import { default as indexaDzmS3WkSWMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/forgot-password/index.vue?macro=true";
import { default as indexExfmKKwLkBMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/index.vue?macro=true";
import { default as search5s1xjY3JnTMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/new-property/search.vue?macro=true";
import { default as indexNoj6OLfxNpMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/address-search/index.vue?macro=true";
import { default as listUrjI1RMKOqMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/address-search/list.vue?macro=true";
import { default as indexBRhcjwEDFgMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/interest/index.vue?macro=true";
import { default as typeK6I21NYlIYMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/interest/type.vue?macro=true";
import { default as checkoutPiVdd5GaAoMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-detail/[id]/checkout.vue?macro=true";
import { default as indexE6qSuoGP4BMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-detail/[id]/index.vue?macro=true";
import { default as _91id_93n7mpqUaYouMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-filling/[id].vue?macro=true";
import { default as _91id_93MdhsigsGdDMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-pricing/[id].vue?macro=true";
import { default as indextaaqdAWo7jMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property/[id]/checkout/index.vue?macro=true";
import { default as success3OAHGJ8thFMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property/[id]/checkout/success.vue?macro=true";
import { default as confirmuPBZHJtoD5Meta } from "/opt/atlassian/pipelines/agent/build/src/pages/password-reset/confirm.vue?macro=true";
import { default as indexMoO299Elz1Meta } from "/opt/atlassian/pipelines/agent/build/src/pages/password-reset/index.vue?macro=true";
import { default as _91id_93HjGh72gZFzMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/pricing-plan/[id].vue?macro=true";
import { default as _91id_93mPlAcvTaL2Meta } from "/opt/atlassian/pipelines/agent/build/src/pages/properties/[id].vue?macro=true";
import { default as sign_45inKUx7sOcazAMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/sign-in.vue?macro=true";
import { default as email_45confirmationuCJpLnew0eMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/sign-up/email-confirmation.vue?macro=true";
import { default as indexmC0NvzcYAsMeta } from "/opt/atlassian/pipelines/agent/build/src/pages/sign-up/index.vue?macro=true";
export default [
  {
    name: confirm_45emailD65kS8uvzmMeta?.name ?? "confirm-email",
    path: confirm_45emailD65kS8uvzmMeta?.path ?? "/confirm-email",
    meta: confirm_45emailD65kS8uvzmMeta || {},
    alias: confirm_45emailD65kS8uvzmMeta?.alias || [],
    redirect: confirm_45emailD65kS8uvzmMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: indexFV2CJAdmUvMeta?.name ?? "dashboard-account-settings",
    path: indexFV2CJAdmUvMeta?.path ?? "/dashboard/account-settings",
    meta: indexFV2CJAdmUvMeta || {},
    alias: indexFV2CJAdmUvMeta?.alias || [],
    redirect: indexFV2CJAdmUvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexrVw7w5X7pMMeta?.name ?? "dashboard-admin",
    path: indexrVw7w5X7pMMeta?.path ?? "/dashboard/admin",
    meta: indexrVw7w5X7pMMeta || {},
    alias: indexrVw7w5X7pMMeta?.alias || [],
    redirect: indexrVw7w5X7pMMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexoZDRIh9S2YMeta?.name ?? "dashboard-listings",
    path: indexoZDRIh9S2YMeta?.path ?? "/dashboard/listings",
    meta: indexoZDRIh9S2YMeta || {},
    alias: indexoZDRIh9S2YMeta?.alias || [],
    redirect: indexoZDRIh9S2YMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/listings/index.vue").then(m => m.default || m)
  },
  {
    name: index4WPWf1xRb3Meta?.name ?? "dashboard-properties",
    path: index4WPWf1xRb3Meta?.path ?? "/dashboard/properties",
    meta: index4WPWf1xRb3Meta || {},
    alias: index4WPWf1xRb3Meta?.alias || [],
    redirect: index4WPWf1xRb3Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/properties/index.vue").then(m => m.default || m)
  },
  {
    name: agreementIZ0oLxgTqbMeta?.name ?? "dashboard-property-id-agreement",
    path: agreementIZ0oLxgTqbMeta?.path ?? "/dashboard/property/:id()/agreement",
    meta: agreementIZ0oLxgTqbMeta || {},
    alias: agreementIZ0oLxgTqbMeta?.alias || [],
    redirect: agreementIZ0oLxgTqbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/agreement.vue").then(m => m.default || m)
  },
  {
    name: indexj5b9OBorAWMeta?.name ?? "dashboard-property-id-checkout",
    path: indexj5b9OBorAWMeta?.path ?? "/dashboard/property/:id()/checkout",
    meta: indexj5b9OBorAWMeta || {},
    alias: indexj5b9OBorAWMeta?.alias || [],
    redirect: indexj5b9OBorAWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: successrvfHhK0tGhMeta?.name ?? "dashboard-property-id-checkout-success",
    path: successrvfHhK0tGhMeta?.path ?? "/dashboard/property/:id()/checkout/success",
    meta: successrvfHhK0tGhMeta || {},
    alias: successrvfHhK0tGhMeta?.alias || [],
    redirect: successrvfHhK0tGhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: managementENQQIXDxYAMeta?.name ?? "dashboard-property-id-management",
    path: managementENQQIXDxYAMeta?.path ?? "/dashboard/property/:id()/management",
    children: [
  {
    name: listingMI5uUmWNKiMeta?.name ?? "dashboard-property-id-management-listing",
    path: listingMI5uUmWNKiMeta?.path ?? "listing",
    meta: listingMI5uUmWNKiMeta || {},
    alias: listingMI5uUmWNKiMeta?.alias || [],
    redirect: listingMI5uUmWNKiMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/listing.vue").then(m => m.default || m)
  },
  {
    name: logsMHH6CVqUcYMeta?.name ?? "dashboard-property-id-management-logs",
    path: logsMHH6CVqUcYMeta?.path ?? "logs",
    meta: logsMHH6CVqUcYMeta || {},
    alias: logsMHH6CVqUcYMeta?.alias || [],
    redirect: logsMHH6CVqUcYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/logs.vue").then(m => m.default || m)
  },
  {
    name: open_45house_45schedulen1NxIwjP23Meta?.name ?? "dashboard-property-id-management-open-house-schedule",
    path: open_45house_45schedulen1NxIwjP23Meta?.path ?? "open-house-schedule",
    meta: open_45house_45schedulen1NxIwjP23Meta || {},
    alias: open_45house_45schedulen1NxIwjP23Meta?.alias || [],
    redirect: open_45house_45schedulen1NxIwjP23Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/open-house-schedule.vue").then(m => m.default || m)
  },
  {
    name: optional_45servicesiJdRZbP7KcMeta?.name ?? "dashboard-property-id-management-optional-services",
    path: optional_45servicesiJdRZbP7KcMeta?.path ?? "optional-services",
    meta: optional_45servicesiJdRZbP7KcMeta || {},
    alias: optional_45servicesiJdRZbP7KcMeta?.alias || [],
    redirect: optional_45servicesiJdRZbP7KcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/optional-services.vue").then(m => m.default || m)
  },
  {
    name: post_45cardsFxpHuyrxJHMeta?.name ?? "dashboard-property-id-management-post-cards",
    path: post_45cardsFxpHuyrxJHMeta?.path ?? "post-cards",
    meta: post_45cardsFxpHuyrxJHMeta || {},
    alias: post_45cardsFxpHuyrxJHMeta?.alias || [],
    redirect: post_45cardsFxpHuyrxJHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/post-cards.vue").then(m => m.default || m)
  },
  {
    name: requester_45requestswYNwEW9QtyMeta?.name ?? "dashboard-property-id-management-requester-requests",
    path: requester_45requestswYNwEW9QtyMeta?.path ?? "requester-requests",
    meta: requester_45requestswYNwEW9QtyMeta || {},
    alias: requester_45requestswYNwEW9QtyMeta?.alias || [],
    redirect: requester_45requestswYNwEW9QtyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/requester-requests.vue").then(m => m.default || m)
  },
  {
    name: showings_45managementUXmjF8EDU0Meta?.name ?? "dashboard-property-id-management-showings-management",
    path: showings_45managementUXmjF8EDU0Meta?.path ?? "showings-management",
    meta: showings_45managementUXmjF8EDU0Meta || {},
    alias: showings_45managementUXmjF8EDU0Meta?.alias || [],
    redirect: showings_45managementUXmjF8EDU0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management/showings-management.vue").then(m => m.default || m)
  }
],
    meta: managementENQQIXDxYAMeta || {},
    alias: managementENQQIXDxYAMeta?.alias || [],
    redirect: managementENQQIXDxYAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/management.vue").then(m => m.default || m)
  },
  {
    name: indexuaM03rLIAvMeta?.name ?? "dashboard-property-id-waiting-screen",
    path: indexuaM03rLIAvMeta?.path ?? "/dashboard/property/:id()/waiting-screen",
    meta: indexuaM03rLIAvMeta || {},
    alias: indexuaM03rLIAvMeta?.alias || [],
    redirect: indexuaM03rLIAvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/property/[id]/waiting-screen/index.vue").then(m => m.default || m)
  },
  {
    name: indextvdeIz9AzbMeta?.name ?? "dashboard-tasks",
    path: indextvdeIz9AzbMeta?.path ?? "/dashboard/tasks",
    meta: indextvdeIz9AzbMeta || {},
    alias: indextvdeIz9AzbMeta?.alias || [],
    redirect: indextvdeIz9AzbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: indexSelVxV52KYMeta?.name ?? "dashboard-users",
    path: indexSelVxV52KYMeta?.path ?? "/dashboard/users",
    meta: indexSelVxV52KYMeta || {},
    alias: indexSelVxV52KYMeta?.alias || [],
    redirect: indexSelVxV52KYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: confirmf0oqORSMNoMeta?.name ?? "forgot-password-confirm",
    path: confirmf0oqORSMNoMeta?.path ?? "/forgot-password/confirm",
    meta: confirmf0oqORSMNoMeta || {},
    alias: confirmf0oqORSMNoMeta?.alias || [],
    redirect: confirmf0oqORSMNoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/forgot-password/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexaDzmS3WkSWMeta?.name ?? "forgot-password",
    path: indexaDzmS3WkSWMeta?.path ?? "/forgot-password",
    meta: indexaDzmS3WkSWMeta || {},
    alias: indexaDzmS3WkSWMeta?.alias || [],
    redirect: indexaDzmS3WkSWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexExfmKKwLkBMeta?.name ?? "index",
    path: indexExfmKKwLkBMeta?.path ?? "/",
    meta: indexExfmKKwLkBMeta || {},
    alias: indexExfmKKwLkBMeta?.alias || [],
    redirect: indexExfmKKwLkBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: search5s1xjY3JnTMeta?.name ?? "new-property-search",
    path: search5s1xjY3JnTMeta?.path ?? "/new-property/search",
    meta: search5s1xjY3JnTMeta || {},
    alias: search5s1xjY3JnTMeta?.alias || [],
    redirect: search5s1xjY3JnTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/new-property/search.vue").then(m => m.default || m)
  },
  {
    name: indexNoj6OLfxNpMeta?.name ?? "onboarding-address-search",
    path: indexNoj6OLfxNpMeta?.path ?? "/onboarding/address-search",
    meta: indexNoj6OLfxNpMeta || {},
    alias: indexNoj6OLfxNpMeta?.alias || [],
    redirect: indexNoj6OLfxNpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/address-search/index.vue").then(m => m.default || m)
  },
  {
    name: listUrjI1RMKOqMeta?.name ?? "onboarding-address-search-list",
    path: listUrjI1RMKOqMeta?.path ?? "/onboarding/address-search/list",
    meta: listUrjI1RMKOqMeta || {},
    alias: listUrjI1RMKOqMeta?.alias || [],
    redirect: listUrjI1RMKOqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/address-search/list.vue").then(m => m.default || m)
  },
  {
    name: indexBRhcjwEDFgMeta?.name ?? "onboarding-interest",
    path: indexBRhcjwEDFgMeta?.path ?? "/onboarding/interest",
    meta: indexBRhcjwEDFgMeta || {},
    alias: indexBRhcjwEDFgMeta?.alias || [],
    redirect: indexBRhcjwEDFgMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/interest/index.vue").then(m => m.default || m)
  },
  {
    name: typeK6I21NYlIYMeta?.name ?? "onboarding-interest-type",
    path: typeK6I21NYlIYMeta?.path ?? "/onboarding/interest/type",
    meta: typeK6I21NYlIYMeta || {},
    alias: typeK6I21NYlIYMeta?.alias || [],
    redirect: typeK6I21NYlIYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/interest/type.vue").then(m => m.default || m)
  },
  {
    name: checkoutPiVdd5GaAoMeta?.name ?? "onboarding-property-detail-id-checkout",
    path: checkoutPiVdd5GaAoMeta?.path ?? "/onboarding/property-detail/:id()/checkout",
    meta: checkoutPiVdd5GaAoMeta || {},
    alias: checkoutPiVdd5GaAoMeta?.alias || [],
    redirect: checkoutPiVdd5GaAoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-detail/[id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexE6qSuoGP4BMeta?.name ?? "onboarding-property-detail-id",
    path: indexE6qSuoGP4BMeta?.path ?? "/onboarding/property-detail/:id()",
    meta: indexE6qSuoGP4BMeta || {},
    alias: indexE6qSuoGP4BMeta?.alias || [],
    redirect: indexE6qSuoGP4BMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93n7mpqUaYouMeta?.name ?? "onboarding-property-filling-id",
    path: _91id_93n7mpqUaYouMeta?.path ?? "/onboarding/property-filling/:id()",
    meta: _91id_93n7mpqUaYouMeta || {},
    alias: _91id_93n7mpqUaYouMeta?.alias || [],
    redirect: _91id_93n7mpqUaYouMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-filling/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93MdhsigsGdDMeta?.name ?? "onboarding-property-pricing-id",
    path: _91id_93MdhsigsGdDMeta?.path ?? "/onboarding/property-pricing/:id()",
    meta: _91id_93MdhsigsGdDMeta || {},
    alias: _91id_93MdhsigsGdDMeta?.alias || [],
    redirect: _91id_93MdhsigsGdDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property-pricing/[id].vue").then(m => m.default || m)
  },
  {
    name: indextaaqdAWo7jMeta?.name ?? "onboarding-property-id-checkout",
    path: indextaaqdAWo7jMeta?.path ?? "/onboarding/property/:id()/checkout",
    meta: indextaaqdAWo7jMeta || {},
    alias: indextaaqdAWo7jMeta?.alias || [],
    redirect: indextaaqdAWo7jMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property/[id]/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: success3OAHGJ8thFMeta?.name ?? "onboarding-property-id-checkout-success",
    path: success3OAHGJ8thFMeta?.path ?? "/onboarding/property/:id()/checkout/success",
    meta: success3OAHGJ8thFMeta || {},
    alias: success3OAHGJ8thFMeta?.alias || [],
    redirect: success3OAHGJ8thFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/onboarding/property/[id]/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: confirmuPBZHJtoD5Meta?.name ?? "password-reset-confirm",
    path: confirmuPBZHJtoD5Meta?.path ?? "/password-reset/confirm",
    meta: confirmuPBZHJtoD5Meta || {},
    alias: confirmuPBZHJtoD5Meta?.alias || [],
    redirect: confirmuPBZHJtoD5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/password-reset/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexMoO299Elz1Meta?.name ?? "password-reset",
    path: indexMoO299Elz1Meta?.path ?? "/password-reset",
    meta: indexMoO299Elz1Meta || {},
    alias: indexMoO299Elz1Meta?.alias || [],
    redirect: indexMoO299Elz1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HjGh72gZFzMeta?.name ?? "pricing-plan-id",
    path: _91id_93HjGh72gZFzMeta?.path ?? "/pricing-plan/:id()",
    meta: _91id_93HjGh72gZFzMeta || {},
    alias: _91id_93HjGh72gZFzMeta?.alias || [],
    redirect: _91id_93HjGh72gZFzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/pricing-plan/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93mPlAcvTaL2Meta?.name ?? "properties-id",
    path: _91id_93mPlAcvTaL2Meta?.path ?? "/properties/:id()",
    meta: _91id_93mPlAcvTaL2Meta || {},
    alias: _91id_93mPlAcvTaL2Meta?.alias || [],
    redirect: _91id_93mPlAcvTaL2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/properties/[id].vue").then(m => m.default || m)
  },
  {
    name: sign_45inKUx7sOcazAMeta?.name ?? "sign-in",
    path: sign_45inKUx7sOcazAMeta?.path ?? "/sign-in",
    meta: sign_45inKUx7sOcazAMeta || {},
    alias: sign_45inKUx7sOcazAMeta?.alias || [],
    redirect: sign_45inKUx7sOcazAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: email_45confirmationuCJpLnew0eMeta?.name ?? "sign-up-email-confirmation",
    path: email_45confirmationuCJpLnew0eMeta?.path ?? "/sign-up/email-confirmation",
    meta: email_45confirmationuCJpLnew0eMeta || {},
    alias: email_45confirmationuCJpLnew0eMeta?.alias || [],
    redirect: email_45confirmationuCJpLnew0eMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/sign-up/email-confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexmC0NvzcYAsMeta?.name ?? "sign-up",
    path: indexmC0NvzcYAsMeta?.path ?? "/sign-up",
    meta: indexmC0NvzcYAsMeta || {},
    alias: indexmC0NvzcYAsMeta?.alias || [],
    redirect: indexmC0NvzcYAsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/src/pages/sign-up/index.vue").then(m => m.default || m)
  }
]